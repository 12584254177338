.FAQ {
    background-color: #282c34;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}
.faq-content {
    /*min-height: 100vh;*/
    width: 90%;
}
.faq-section {
    border-top: 2px solid #61dafb;
    border-left: 2px solid #61dafb;
    margin-bottom: 30px;  /* Space between sections */
    border-bottom: 2px solid #61dafb;
    padding-left: 8px;

}

.faq-item {
    border-bottom: 1px solid #444;  /* Subtle divider between questions */
    padding-bottom: 10px;
}
.faq-section:hover {
    cursor: pointer;
}

.faq-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-decoration: underline;
    background-color: ;
}

.question {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;  /* White text for better contrast */
    transition: color 0.3s ease;
    text-align: start;
    margin-top: .75rem;
}



.answer {
    font-size: 16px;
    color: #ddd;  /* Light gray for the answer */
    padding-left: 20px;
    margin-top: 10px;
    text-align: start;
}

.toggle-icon {
    font-size: 20px;
    color: #61dafb;  /* Blue color for the plus/minus icons */
    transition: transform 0.3s ease;
}

/* Optional: Smooth rotation effect when icon changes */
.question:hover .toggle-icon {
    transform: scale(1.1);
}

.faq-section h2 {
    font-size: 22px;
    font-weight: bold;
    color: #ffffff;  /* White for section headers */
    margin-bottom: 15px;
    text-transform: uppercase;
}

.question-text {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.question-text::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 2px;
    background-color: #61dafb;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease-in-out;
}

/* Animate underline from left to right when selected */
.underline-active::after {
    transform: scaleX(1);
    transform-origin: left;
    transition: transform 0.3s ease-in-out;
}

/* Animate underline from right to left when deselected */
.underline-inactive::after {
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease-in-out;
}
