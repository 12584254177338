.Contact {
    background-color: #282c34;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    padding: 20px;
}
.contact-container {
    background-color: #f5f5f5;
    padding: 40px;
    border-radius: 8px;
    max-width: 600px;
    margin: 50px auto;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    color: black;
}

.contact-title {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
}

.contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.contact-message {
    width: 100%;
    max-width: 500px;
    padding: 12px;
    font-size: 1.1rem;
    border-radius: 8px;
    border: 1px solid #ccc;
    resize: vertical;
    box-sizing: border-box;
    min-height: 120px;
    font-family: 'Arial', sans-serif;
}

.contact-submit {
    padding: 12px 30px;
    font-size: 1.2rem;
    background-color: #128C7E; /* WhatsApp Green */
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.contact-submit:hover {
    background-color: #128C7E; /* Darker WhatsApp Green */
}

.contact-submit:active {
    transform: scale(0.98);
}
