/* LoadingSpinner.css */
.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    width: 100%;   /* Full width */
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Optional, adds overlay background */
    z-index: 98; /* Ensure the spinner is above other content */
}

.spinner {
    border: 4px solid #f3f3f3; /* Light gray border */
    border-top: 4px solid #6200ee; /* Purple color for the spinner */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
}

/* Define the spin animation */
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
