.Home {
    background-color: #282c34;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    position: sticky;
    padding-left: 5px;
    padding-right: 5px;
    max-width: 100%;
    overflow: hidden;
}

.features {
    display: flex;
    flex-direction: column;
    list-style: none;
    text-align: start;
}

.area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

/* Apply max-width of 600px only for desktop (screens wider than 768px) */
@media (min-width: 768px) {
    .Home {
        max-width: 600px;
        margin: 0 auto; /* Centers it */
    }
}

.flyer {
    width: 100%;
    margin-top: -2rem;
}

@media (max-width: 768px) {
    .flyer {
        margin-left: -2rem;
        width: 120%;
        overflow: hidden;
    }
}
