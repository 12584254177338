.App {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #282c34;

}
.toggle-header-btn {
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: space-around;
  background-color: #282c34;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  width: 100%;
  border: none;
  position: sticky;
  top: 0;
  z-index: 100;
  border-bottom: 4px solid #283134;
  padding-top: 1rem;
}
.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: sticky;
  top: 6%;
  z-index: 2;
  overflow: hidden;
  max-height: 1000px; /* Large max-height for smooth transition */
  transition: max-height 0.5s ease-in-out; /* Add transition for smooth effect */
  padding-top: 1.5rem;
  padding-bottom: 1rem;
}
.App-header.hidden {
  max-height: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out, opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}
.white-space {
  z-index: 99;
  width: 100%;
  margin-bottom: 2rem;
}
.white-space-bottom {
  z-index: 99;
  width: 100%;
  padding-bottom: 1.3rem;
  border-bottom: 4px solid #16373f;
}


.outer-header-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  color: white;
  position: sticky;
  top: 0;
}

.header-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  color: white;
  position: sticky;
  top: 0;
}

.header-container-value {
  background-color: #282c34;
  font-weight: bold;
  color: white;
  text-decoration: none;
  border: 4px solid #61dafb;
  font-size: 1.2rem;
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  text-align: center;
}

.header-container-value-i {
  display: flex;
  position: sticky;
  justify-content: center;
  top: 20%;
}



.App-logo-header {
  height: 20vmin;
  pointer-events: none;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-body {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: sticky;
}
.App-footer {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: sticky;
  bottom: 0;
  border-top: 4px solid #283134;
  padding-top: 1.3rem;
}

.App-link {
  color: #61dafb;
}

.logo-container {
  position: relative;
  display: inline-block;
  margin-bottom: 3rem;
}
.typing-overlay {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.8rem;
  color: white; /* Adjust color as needed */
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  white-space: nowrap;
}

.typing-text {
  border-right: 2px solid white; /* Cursor effect */
  padding-right: 5px;
  animation: blink 0.8s infinite;
}

@keyframes blink {
  50% { border-color: transparent; }
}



